import { useEffect, useState } from 'react'
import { FormIntereses } from './FormIntereses'
import { FooterLinksItems } from './partials/FooterLinksItems'
import { FooterDescription } from './partials/FooterDescription'
import { FooterContact } from './partials/FooterContact'
import { Config } from '../../config/Config'

export function FooterComponent() {
  const [footer, setFooter] = useState([])
  const [viewForm, setViewForm] = useState(false)

  useEffect(() => {
    fetch(Config.baseUrlJsonMaster)
      .then((resp) => resp.json())
      .then((data) => setFooter(data.footer))
    //   return () => {}
  }, [])

  return (
    <footer role="contentinfo" className="footer">
      {viewForm && (
        <FormIntereses viewForm={viewForm} setViewForm={setViewForm} />
      )}
      <div className="container">
        <div className="footer__flex">
          <div className="d-flex flex-row justify-content-between flex-wrap">
            <FooterLinksItems footer={footer} />
            <FooterDescription viewForm={viewForm} setViewForm={setViewForm} />
            <FooterContact footer={footer} />
          </div>
        </div>
      </div>
    </footer>
  )
}
