
export const FooterContact = ({ footer }) => {
    return <div className="footer__contact">
        <h3> Contacto </h3>
        <ul>
            {
                footer.length > 0 && footer[2].contacto.length > 0 &&
                <>
                    <li> {footer[2].contacto[0].telefono} </li>
                    <li> {footer[2].contacto[0].correo_institucional} </li>
                    <li> {footer[2].contacto[0].correo_notificaciones} </li>
                    <li> {footer[2].contacto[0].correo_rita} </li>
                </>
            }
        </ul>
    </div>
}
