import React from 'react';
import { Link } from 'react-router-dom'
import sitemapList from '../../data/sitemap.json'

export const sitemap = React.memo(() => {
    // Filtrar los elementos para eliminar 'leche' y 'carne' Cambios
    const filteredSitemapList = sitemapList.filter(item => item.name !== 'leche' && item.name !== 'carne');

    const sistema = (storage, label, id) => {
        if( storage === undefined ) return;
        window.localStorage.setItem('sistema', JSON.stringify({ label: label, value: id }));
        window.localStorage.setItem('contexto', 1);
        window.localStorage.setItem('tab', 1);
        localStorage.setItem('tipo_riesgo', 1);
    }


    return <div className='container site-map'>
        <h3>Mapa del sitio</h3>
        <ul>
            {
                filteredSitemapList.length > 0 &&
                filteredSitemapList.map(({ path, label, subitems, id }) => {//Cambios realizados
                    return <>
                        <li key={id}>
                            <Link to={path}>
                                {label}
                                {
                                    subitems && subitems.length > 0 &&
                                    <ul>
                                        {
                                            subitems.map(({ path, label, text, subitems, storage, id } ) => {
                                                return <li
                                                    key={id}
                                                >
                                                    <Link
                                                        onClick={
                                                            () => sistema(storage, label, id)
                                                        }
                                                        to={path}>
                                                        {text}
                                                    </Link>
                                                </li>
                                            })
                                        }
                                    </ul>
                                }
                            </Link>
                        </li>
                    </>
                })
            }
        </ul>
    </div>
})
