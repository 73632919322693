export function Modal({
    title,
    content,
    width = 600,
    height = 386,
    viewModal,
    changeViewModal,
    urlIcon }) {

    const modal = (
        <div className="overlay">
            <div className="content-modal" style={{ width: `${width}px`, minHeight: `${height}px` }}>
                <div>
                    <div className="header-modal row">
                        <div className="content d-flex align-items-center col-8">
                            { urlIcon && <img src={urlIcon} alt="Información_icono"/>}
                            <h3 className="Labeles">{ title }</h3>
                        </div>
                        <div className="col-auto d-flex flex-row-reverse">
                            <button title="cerrar" className="btn-close" role="button" aria-label="Boton para cerrar la información" onClick={() => { changeViewModal(false) }}></button>
                        </div>
                    </div>
                    <div>
                        {content}
                    </div>
                </div>
            </div>
        </div>
    )

    return viewModal ? modal : ''

}

export default Modal;