
import ReactDOM from 'react-dom';

// Componente principal - con cambios de prueba //
import { AppComponent } from './AppComponent';

import './index.css';
import './styles/app.scss';
import './styles/utils/dark.scss';

ReactDOM.render(
  // <AppComponent tabIndex="0" aria-label="menu" onKeyDown={() => console.log('key pressed')}/>,
  <AppComponent />,
  document.getElementById('root')
);