
export const FooterDescription = ({ viewForm, setViewForm }) => {
    return <div className="footer__description">
        <h3> Sistema de información para la gestión de riesgos agropecuarios - SIGRA </h3>
        <p>¿Estás interesado en conocer más sobre nosotros?</p>
        <button className='btn btn-light btn-lg btn-block' aria-label="Ir al formulario de interes"
            onClick={() => { setViewForm(!viewForm) }}
        >
            Ir al formulario de interés
        </button>
    </div>
}
