import React from 'react'
import { NavLink, useLocation, Link } from 'react-router-dom'

export const ListItems = React.memo(({ element }) => {
  const location = useLocation()

  const handleGetRiesgo = (id) => {
    const riesgoId = localStorage.setItem('tipo_riesgo', id)
  }

  const removeAccents = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  let containHas = element.path
  let exatPath = containHas.includes('#')

  const openCatalogo = (path) => {
    window.open(path, '_blank')
  }

  return (
    <>
      <li key={element.id}>
        <NavLink
          onClick={() => handleGetRiesgo(element.id)}
          activeClassName={exatPath ? '' : 'active'}
          // to={`${element.path}`}
          // to={exatPath ? '/#' : `${element.path}`}
          to={exatPath ? '#' : removeAccents(element.path)}
          exact
        >
          {element.label}
          <ul className="submenu">
            {element.subitems && element.subitems.length > 0
              ? element.subitems.map((element) => {
                // return <li key={element.id}> <NavLink to={`${element.path}`} exact> {element.label} </NavLink> </li>
                // { console.log( `${location.pathname.slice(1)}/${location.hash}` ) }
                return (
                  <li key={element.id}>
                    {element.target ? (
                      <Link to="" onClick={() => openCatalogo(element.path)}>
                        {element.label}
                      </Link>
                    ) : (
                      // <Link to={'https://catalogometadatos.upra.gov.co:8443/uprageonet/srv/spa/catalog.search#/home'}>{element.label}</Link>
                      <NavLink to={removeAccents(element.path)} exact>
                        {element.label}
                      </NavLink>
                    )}
                  </li>
                )
              })
              : ''}
          </ul>
        </NavLink>
      </li>
    </>
  )
})
