import { Link } from 'react-router-dom'

export const FooterLinksItems = ({ footer }) => {
  return (
    <div className="footer__link-items">
      <div className="logos-footer">
        {footer.length > 0 &&
          footer[0].logos.length > 0 &&
          footer[0].logos.map((logos, idx) => (
            <div key={idx}>
              <img src={logos} alt="logos" />
            </div>
          ))}
      </div>
      <ul>
        {footer.length > 0 &&
          footer[0].informacion.length > 0 &&
          footer[0].informacion.map(({ id, label, path }) => {
            return (
              <li key={id}>
                <Link to={path}> {label} </Link>
              </li>
            )
          })}
      </ul>
    </div>
  )
}
