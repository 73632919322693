import React, { useContext, useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { AppContext } from "../../../context/AppContexto";
import Swal from 'sweetalert2';

export const TopBar = React.memo(() => {
  const {
    infoTobBar,
    darkTheme,
    setDarkTheme,
    size,
    setSize,
    settermn,
  } = useContext(AppContext);

  const history = useHistory();
  const patname = history.location.pathname;
  const [dataForm, setDataForm] = useState("");

  const handleChange = (event) => {
    setDataForm(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (dataForm.length === 0) {
      if (Swal.isVisible() === false) {
        Swal.fire("Introduce un término de búsqueda");
        return;
      }
    }
    localStorage.setItem("term", dataForm);
    settermn(dataForm);
    history.push(`/search/${dataForm}`);
  };

  return (
    <>
      <div className="section-top">
        <div className="section-top__left">
          <figure role="figure" aria-label="Contenedor logo SIGRA">
            <Link to="/inicio">
              <img
                onClick={() => {history.push("/inicio")
                }}
                className="section-top__logo-sigra"
                src={darkTheme ? infoTobBar.logo_sigra_claro : infoTobBar.logo_sigra_oscuro}
                alt="Logo SIGRA"
              />
            </Link>
          </figure>
          <figure
            className="figure-mincultura"
            role="figure"
            aria-label="Contenedor logo MinCultura"
          >
            <img
              style={{ width: "100%", maxWidth: "300px", height: "57px" }}
              className="section-top__logo-mincultura"
              src={darkTheme ? infoTobBar.logo_minagricultura_oscuro : infoTobBar.logo_minagricultura_claro}
              alt="logo MinCultura color"
              loading="lazy"
            />
          </figure>

          <img
            style={{ width: "130px", marginLeft: '1rem' }}
            // className="section-top__logo-mincultura"
            src={darkTheme ? infoTobBar.logo_potencia_negativo : infoTobBar.logo_potencia_color}
            alt="logo potencia de la vida color"
            loading="lazy"
          />

          <div className="section-top__options-themes">
            <button
              onClick={() => {
                setDarkTheme(!darkTheme);
              }}
            >
              <img
                className="section-top__options-themes--brillo"
                src={darkTheme ? infoTobBar.icono_contraste_negativo : infoTobBar.icono_contraste}
                width="32"
                height="32"
                alt="Icono brillo"
                loading="lazy"
              />
            </button>
            <button
              disabled={size === 2}
              onClick={() => {
                setSize(size + 1);
              }}
            >
              <img
                className="section-top__options-themes--aumentar"
                src={darkTheme ? infoTobBar.icono_fuente_mas_negativo : infoTobBar.icono_fuente_mas}
                width="32"
                height="32"
                alt="Icono Aumentar Fuente"
                loading="lazy"
              />
            </button>
            <button
              onClick={() => {
                setSize(size - 1);
              }}
            >
              <img
                className="section-top__options-themes--disminuir"
                src={darkTheme ? infoTobBar.icono_fuente_menos_negativo : infoTobBar.icono_fuente_menos}
                width="32"
                height="32"
                alt="Icono Disminuir Fuente"
                loading="lazy"
              />
            </button>
          </div>
        </div>

        {/*
          (patname && patname === "/inicio") || (patname !== "/" &&
            <div className="section-top__rigth">
              <div className="section-top__search">
                
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <div className="hero-sigra-text__form">
                    <input
                      aria-label="Formulario de busqueda"
                      className="hero-sigra-text__input"
                      type="search"
                      onChange={handleChange}
                      placeholder="Ejemplo: Amenaza"
                    />

                    <button
                      type="submit"
                      className="section-top__sigra-text__btn-search"
                    >
                      Buscar
                    </button>
                  </div>
                </form>
              </div>
            </div>//cambio para ocultar el buscador
          )
        */}
      </div>
    </>
  );
});