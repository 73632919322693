import React from 'react'
import { BiMenu } from "react-icons/bi";

export const ToggleMenu = React.memo(({isView, setIsView}) => {
    return (
        <>

            <div>
                <button
                    className='btn-menu'
                    aria-label="Menu boton"
                    onClick={() => { setIsView(!isView) }}
                >
                    <BiMenu
                        size={40}
                        color="#8CC63F"
                    />
                </button>
            </div>

        </>
    )
})
