
export enum RequestUrls {
    _baseApiURL = 'https://sigraservicioamenaza.azurewebsites.net',
    _searchSite = 'https://sigraserviciobuscador.azurewebsites.net',
    _emailIntereses = 'https://sigraserviciofooter.azurewebsites.net/api/interes',
    _emailFooter = 'https://sigraserviciofooter.azurewebsites.net/api/footer',
    _featureMapServer = 'https://mapserverapiprod.azurewebsites.net/api/feactures',
    _layerMonetario = 'https://mapserverapiprod.azurewebsites.net/api/layer_riesgo_monetario',
    _printService = 'https://geoserviciossigra.upra.gov.co/servergeopro/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task',
    _fechaExposicion = 'https://sigraservicioexposicion.azurewebsites.net/api/FechaExposicion?code=CXYdjkbkHpT8-z7oBvaZmcjM22OtOlHFrt_gOILvq9XeAzFuPdV_mg==',

    PERIODO = 'https://serviciofiltros.azurewebsites.net/api/periodo?code=5fOqoVbcJfFoCDkmrrK6xRi2f%2FV6BpiaGcrA3fa7uA9jgMME2dkl8A%3D%3D',
    DEPARTAMENTOS = 'https://pythonrestapides.azurewebsites.net/api/departamento?code=P7eGtxx%2FSjKFGTcp4bLYbBPd2r4E1DNNySTLLEOuBoyzfBeAVaD4xA%3D%3D',
    MUNICIPIOS = 'https://serviciofiltros.azurewebsites.net/api/municipio?code=5fOqoVbcJfFoCDkmrrK6xRi2f%2FV6BpiaGcrA3fa7uA9jgMME2dkl8A%3D%3D',
    HELADAS = 'https://heladasapirest.azurewebsites.net/api/HeladasApiRest',
    ENSO = 'https://ensoapiprod.azurewebsites.net/api/ensoapi',
    GET_URL_MOSAICO = 'https://heladasapirest.azurewebsites.net/api/mosaicos',
    GET_PARAMETROS_GI = 'https://generoinclusionapi.azurewebsites.net/api/parametrosgi?code=RAZxosJJkc3dGKdpiUQrIrD3naU3GpvXDXxMQwpJi5wCIA7yNWLoWg==',
    GET_CAPAS_GI = 'https://generoinclusionapi.azurewebsites.net/api/consultarcapas?code=RAZxosJJkc3dGKdpiUQrIrD3naU3GpvXDXxMQwpJi5wCIA7yNWLoWg==',
    GET_URL_MPS = 'https://mpsfunction.azurewebsites.net/api/APImps',
    LIMITES_GEO_DTO = 'https://geoserviciossigra.upra.gov.co/servergeopro/rest/services/Limites-Geograficos-Colombia/Departamentos_Colombia/MapServer/0',
    LIMITES_GEO_MPO = 'https://geoserviciossigra.upra.gov.co/servergeopro/rest/services/Limites-Geograficos-Colombia/Municipios_Colombia/MapServer/0',
    // dev GET_URL_MPS = 'https://mpsfunctions.azurewebsites.net/api/APImps'
    LIMITES_GEO_COL_DTO = 'https://geoserviciossigra.upra.gov.co/image/rest/services/Departamentos_Colombia/MapServer',
    LIMITES_GEO_COL_MPO = 'https://geoserviciossigra.upra.gov.co/image/rest/services/Municipios_Colombia/MapServer',
    GET_LAYER_MONETARIO = 'https://mapserverapiprod.azurewebsites.net/api/layer_riesgo_monetario',
}

export interface RequestAmenaza {
    id_cultivo: number,
    id_riesgo: number,
    id_modulo: number,
    id_contexto: number,
    id_amenaza?: number
}

export interface RequestPeriodo {
    id_sist_productivo: number,
    id_contexto: number,
    id_amenaza: number
}

export interface RequestMunicipio {
    codigo: number
}

export interface RequestHeladas {
    sistema_productivo: number,
    tipo_amenaza: number,
    mes: string,
    year: number,
    periodo: string,
    departamento: string,
    municipio: string,
    contexto: number
}
export interface RequestEnso {
    year: string,
    month: string,
    periodo: string
}
export interface RequestMosaic {
    sistema_productivo: number,
    tipo_amenaza: number
}

export interface RequestParametros {
    capa: number,
    geografia: string | number
}
