import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'; // useHistory
import { AppContext } from '../../../context/AppContexto'

export const Search = React.memo(() => {

    const [searchValue, setSearchValue] = useState('');
    const { showSearch, setshowSearch } = useContext(AppContext);

    // console.log( showSearch )

    return (
        <>
            {/* <div className="section-top__serach">
                {showSearch !== true ?
                    <form role="search" className="hero-sigra-text__form">
                        <i className="fas fa-search hero-sigra-text__icon-search"></i>
                        <input
                            className="hero-sigra-text__input"
                            type="text"
                            placeholder="Escribe lo que buscas"
                            // onChange={onsearch}
                            value={searchValue} />
                        <NavLink to={`/search/${searchValue}`}>
                            <button className="section-top__sigra-text__btn-search" to="/search">
                                Buscar
                            </button>
                        </NavLink>
                    </form> : ''}
            </div> */}
        </>
    )
})
