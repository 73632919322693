import { lazy, Suspense, useContext } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";

import { AppContext } from '../context/AppContexto';

import { HeaderComponent } from '../components/header/HeaderComponent';
import { FooterComponent } from '../components/footer/FooterComponent';
import { sitemap } from '../pages/sitemap/sitemap';
import { PoliticasPage } from '../pages/politicas/PoliticasPage';

const SearchPage = lazy(() => import(/* webpackChunkName: "SearchPage" */'../pages/search/SearchPage'));
const Page404 = lazy(() => import(/* webpackChunkName: "Page404" */'../pages/404/Page404'));
const HomePage = lazy(() => import(/* webpackChunkName: "HomePage" */'../pages/home/HomePage'));
const PortalPage = lazy(() => import(/* webpackChunkName: "PortalPage" */'../pages/about/PortalPage'));
const AntecedentesPage = lazy(() => import(/* webpackChunkName: "AntecedentesPage" */'../pages/about/AntecedentesPage'));
const RiesgoAgroclimaticoPage = lazy(() => import(/* webpackChunkName: "RiesgoAgroclimaticoPage" */'../pages/riesgo-agroclimatico/RiesgoAgroclimaticoPage'));
const ComparativaPage = lazy(() => import(/* webpackChunkName: "ComparativaPage" */'../pages/riesgo-agroclimatico/ComparativaPage'))
const RiesgoDetalle = lazy(() => import(/* webpackChunkName: "RiesgoDetalle" */'../pages/riesgo-detalle/RiesgoDetalle'));

export const AppRouter = () => {

    const { darkTheme, size, _sizes } = useContext(AppContext);

    return (
        <>
            <div role="main" className={darkTheme ? 'dark' : ''}>
                <Router>
                    <HeaderComponent role="header" />
                    <div className={_sizes[size] ? _sizes[size] : ''}>
                        <Suspense fallback={<div className="lds-ring"><div></div><div></div><div></div><div></div></div>}>
                            <Switch>
                                <Route exact path="/inicio" component={HomePage} />
                                <Route exact path="/quienes-somos/portal" component={PortalPage} />
                                <Route exact path="/quienes-somos/antecedentes" component={AntecedentesPage} />
                                <Route exact path="/riesgo-agroclimatico" component={RiesgoAgroclimaticoPage} />
                                <Route exact path="/riesgo-agroclimatico/comparativa" component={ComparativaPage} />
                                <Route exact path="/riesgo-agroclimatico/:riesgoId" component={RiesgoDetalle} />

                                {/* <Route exact path="/calculos-actuariales" component={CalculosActuarialesPage} />
                                <Route exact path="/genero-inclusion" component={GeneroInclusionPage} /> */}

                                <Route exact path="/search/:searchValue" component={SearchPage} />
                                <Route exact path="/mapa-del-sitio" component={sitemap} />
                                <Route exact path="/politicas-de-seguridad" component={PoliticasPage} />
                                <Route exact path="/" component={HomePage} />
                                <Route exact path="/*" component={Page404} />
                                <Redirect to="/" />
                            </Switch>
                        </Suspense>
                    </div>
                    <FooterComponent />
                </Router>
            </div>
        </>
    )
}
