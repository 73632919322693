import { useContext } from "react";
import { AppContext } from "../../context/AppContexto";

export const PoliticasPage = () => {

    const { darkTheme } = useContext(AppContext);

    return (
        <div className='container'>
            <h1 className='text-center mt-5 mb-5'>Política de tratamiento y protección de datos personales</h1>
            <p>La Unidad de Planificación Rural Agropecuaria (UPRA) es una entidad que se encuentra comprometida con la protección de la información de carácter privada y confidencial que es obtenida en cumplimiento de su objetivo misional.</p>

            <p>Nuestras políticas y procedimientos tienen como base el marco legal para la protección del habeas data y datos personales contenido en la Ley 1581 de 2012 y sus decretos reglamentarios. Con relación a los datos personales, se recolecta exclusivamente aquella información que ha sido suministrada voluntariamente por nuestros funcionarios, contratistas, proveedores, exfuncionarios, excontratistas, usuarios y visitantes, entre otros. Se considera datos personales toda aquella información personal que el titular da a conocer a la UPRA de manera libre y voluntaria. El usuario puede modificar o actualizar parte de esta información en cualquier momento, y de esta manera la UPRA podrá brindarle servicios y contenidos acordes con su perfil. Asimismo, la UPRA informa al titular de la información que se compromete a adoptar una política de confidencialidad y protección de datos, con el objeto de proteger la privacidad de la información personal obtenida a través del titular de la información.</p>

            <p>La UPRA advierte al titular de los datos y se compromete a que no compartirá ni revelará la información confidencial con terceros, excepto que tenga expresa autorización de dicho titular, cuando ha sido requerido por orden judicial o legal o para proteger los derechos de propiedad intelectual u otros derechos. En todo caso, la información proporcionada por el titular está resguardada administrativa, jurídica y tecnológicamente.</p>

            <p>La UPRA utiliza la información recolectada para fines estadísticos y de conocimiento sobre el usuario y la consulta de productos y servicios que le son ofrecidos a través de la página web, tales como contenidos, publicaciones, información geográfica, presentaciones, así como en eventos y actividades presenciales a los que sea convocado dicho usuario. De igual manera, los datos personales son referente para el envío de boletines con información de interés para el usuario.</p>

            <p>El titular de la información, al suministrar, entregar o enviar cualquier tipo de información personal a la UPRA, acepta que dicha información va a ser utilizada de acuerdo con el presente documento relacionado con la política para el tratamiento de datos personales. Se deja constancia que la UPRA no utilizará los datos suministrados para actividades o propósitos diferentes a los aquí previstos. En todo caso, en el evento en que dicha información personal sea utilizada para otros propósitos, su utilización deberá estar amparada en alguna de las excepciones que, sobre el particular, prevé la legislación o contar con la autorización expresa del titular.</p>

            <p>Respecto a los canales o medios en los que la UPRA obtiene información de tipo personal, corresponden, entre otros, a: i) relación comercial, profesional, laboral con el respectivo funcionario, contratista, proveedor, exfuncionario, excontratista o visitante; ii) aplicación a procesos de selección; iv) asistencia a capacitaciones, seminarios o cursos; v) remisión de correos electrónicos solicitando información; vi) requerimientos e inquietudes dirigidos a atención al usuario.</p>

            <p>El usuario, en cualquier momento, tiene la posibilidad de revocar la autorización para el almacenamiento de su información, así como para anular la suscripción a los boletines periódicos. Si el usuario desea dejar de recibir información emitida por UPRA, debe comunicarse a través del correo electrónico <span style={{ color: darkTheme ? '#8CC63F' : 'blue' }}>atencionalusuario@upra.gov.co</span> ​.</p>

            <p>Por último, la UPRA se reserva la potestad de modificar el presente documento para adaptarlo a nuevos requerimientos legislativos, jurisprudenciales y técnicos.​</p>
        </div>
    )
}
