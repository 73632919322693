import React, { useContext, useEffect, useState } from 'react';

import { TopBar } from './partials/TopBar';
import { Search } from './partials/Search';
import { ToggleMenu } from './partials/ToggleMenu';
import { MainMenu } from './partials/MainMenu';
import { AppContext } from '../../context/AppContexto';

export const HeaderComponent = React.memo(() => {

    const [isView, setIsView] = useState(false)
    const { listItems } = useContext( AppContext );

    useEffect(() => {
        setIsView(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.href])

    return (
        <>
            <header className="header">
                <TopBar />
                <Search />
                <ToggleMenu 
                    isView={isView} 
                    setIsView={setIsView}
                />
            </header>

            <nav className="nav-top">
                <MainMenu 
                    listItems={listItems} 
                    isView={isView}
                />
            </nav>
        </>
    )
})
