import { useContext, useState } from "react";
import { Modal } from "../modals/Modal";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AppContext } from "../../context/AppContexto";
import { Link } from 'react-router-dom';
import { RequestUrls } from "../../api/API";

export function FormIntereses({ viewForm, setViewForm }) {
  const { itemsInteres, darkTheme } = useContext(AppContext);

  const sendForm = (values) => {
    Swal.fire({
      title: "Enviando información...",
      allowOutsideClick: false,
      customClass: {
        popup: darkTheme ? "swal2-popup-dark" : "",
      },
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const { correo, intereses, mensaje } = values;

    const intereseString = [];
    intereses.map((intres) => {
      intereseString.push(intres.value);
    });

    const setBody = {
      correo,
      intereses: intereseString.toString(),
      descripcion: mensaje,
    };

    fetch(`${RequestUrls._emailFooter}`, {
      method: "POST",
      body: JSON.stringify(setBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((resp) => {
        console.log(`${resp}`);
        if (resp.status === 200) {
          Swal.fire({
            title: "Tus datos han sido enviados correctamente.",
            icon: "success",
            confirmButtonText: "Continuar",
            customClass: {
              popup: darkTheme ? "swal2-popup-dark" : "",
            }
          });
          resetForm();
          setFieldValue("intereses", []);
        } else {
          Swal.fire({
            title: "Al parecer hubo un error",
            text: `${resp.errors}`,
            icon: "warning",
            confirmButtonText: "Continuar",
            customClass: {
              popup: darkTheme ? "swal2-popup-dark" : "",
            },
          });
        }
      })
      .catch((error) => console.log(error));
  };

  // handleBlur
  const {
    values,
    handleChange,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      correo: "",
      intereses: [],
      mensaje: "",
      terminos: "",
    },
    onSubmit: (values) => {
      sendForm(values);
    },
    validationSchema: Yup.object({
      correo: Yup.string()
        .email("El correo no tiene un formato válido")
        .required("El correo es obligatorio"),
      intereses: Yup.array()
        .required("El correo es obligatorio")
        .min(2, "Debe seleccionar al menos 2 opciones"),
      terminos: Yup.boolean()
        .required("Debes aceptar nuestos términos y condiciones")
        .oneOf([true], "Debes aceptar nuestos términos y condiciones"),
    }),
  });

  const handleIntrestingChecks = (values) => {
    // if( values.length >= 2 ) {
    //     setErrorsCheck('');
    // } else {
    //     setErrorsCheck('Debe contener por los menos dos opciones')
    // }
    // setCheckList([...checkList, values]);
    setFieldValue("intereses", values);
  };

  const [btnDisable, setBtnDisable] = useState(true);

  const onChange = (value) => {
    if (value.length === 0) return;
    setBtnDisable(false);
  };

  const contentModal = (
    <div className="form-footer">
      <form onSubmit={handleSubmit}>
        <p>
          {" "}
          Los campos marcados con (<span className="asterisco">*</span>) son
          obligatorios:{" "}
        </p>

        <div className="form-group mt-3">
          <label htmlFor="emailInput">
            Por favor ingrese su correo: <span className="asterisco">*</span>{" "}
          </label>
          <input
            name="correo"
            type="email"
            className="form-control correo-footer"
            classNamePrefix="react-select"
            id="emailInput"
            aria-describedby="emailHelp"
            placeholder="Ingrese su correo"
            onChange={handleChange}
            // onBlur={handleBlur}
            value={values?.correo}
          />
        </div>
        {errors.correo && touched.correo && (
          <p className="text-danger"> {errors?.correo} </p>
        )}

        <div id="accordion" className="mt-4 mb-4">
          <label htmlFor="">
            A continuación seleccione los temas del SIGRA sobre los cuales desea
            recibir información (estos datos se actualizarán cada vez que
            registre nuevos intereses):{" "}
          </label>
          <Select
            isMulti
            id="intereses"
            name="intereses"
            options={itemsInteres}
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={handleIntrestingChecks}
            value={values.intereses}
            aria-label="Informacion de interes"
            tabIndex={0}
            placeholder={
              <div className="search-placeholder">Ejemplo: Vulnerabilidad</div>
            }
          />
        </div>
        {errors.intereses && touched.intereses && (
          <p className="text-danger"> {errors?.intereses} </p>
        )}

        <div className="form-group">
          <label className="mt-2 mb-3" htmlFor="">
            Por favor cuéntanos que otro tema te gustaría conocer:
          </label>
          <textarea
            tabIndex={0}
            aria-label="Sugerencia de temas de interes"
            name="mensaje"
            className="form-control"
            id=""
            rows="5"
            onChange={handleChange}
            value={values?.mensaje}
          ></textarea>
        </div>

        <div className="form-group">
          <label className="mt-2 mb-3" htmlFor="terminos">
            <input
              type="checkbox"
              name="terminos"
              id="terminos"
              onChange={handleChange}
              value={values?.terminos}
            />
            Autorizo a SIGRA al tratamiento de mis datos personales
          </label>
        </div>
        {errors.terminos && touched.terminos && (
          <p className="text-danger"> {errors?.terminos} </p>
        )}

        <div className="form-group">
          <p style={{ fontSize: '1.2rem', lineHeight: '1.5rem', textAlign: 'justify' }}>La información y datos personales suministrados a la UPRA por parte de sus usuarios a través de los diferentes canales de recolección de información son utilizados para fines de control de asistencia, estadísticos y de conocimiento; y, principalmente consolidados con el fin de orientar acciones de difusión de productos, servicios, convocatorias a eventos presenciales o virtuales, envío de noticias y boletines con información de interés para el usuario. Bajo ninguna circunstancia se realizará tratamiento de datos personales con fines de comercialización o circulación. En todo caso aplicarán las excepciones de ley. El suministro de dichos datos autoriza a la UPRA a recolectarlos, almacenarlos, usarlos y actualizarlos conforme a la <strong>Ley 1581 de 2012</strong> y sus decretos reglamentarios y al <strong>MANUAL DE PROTECCIÓN DE DATOS PERSONALES E INFORMACIÓN PROPIEDAD O BAJO RESPONSABILIDAD DE LA UPRA</strong>, adoptado por la Entidad. Los titulares de los datos recolectados podrán ejercer sus derechos de conocer, actualizar, rectificar y suprimir sus datos personales, a través del correo electrónico atencionalusuario@upra.gov.co. Recibida la solicitud en los términos dispuestos por la UPRA, se le dará trámite al requerimiento según lo establecido por la ley. La información aquí consignada, la ha suministrado el usuario de forma voluntaria, garantizando su veracidad. La UPRA se reserva la potestad de modificar el presente documento para adaptarlo a nuevos requerimientos legislativos, jurisprudenciales y técnicos.
          </p>
        </div>

        <div className="form-group">
          <div className="mt-2 mb-3">
            <ReCAPTCHA
              sitekey={`${process.env.REACT_APP_RECAPTCHA_API_KEY}`}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="form-group mt-5 box-btn">
          <button
            disabled={btnDisable}
            type="submit"
            className="btn btn-footer"
            aria-label="Enviar formulario"
          >
            Enviar formulario
          </button>
        </div>
      </form>
    </div>
  );

  return (
    <Modal
      arial-label={"Pop up respuesta formulario de interes"}
      tabIndex={0}
      title="Formulario de intereses"
      content={contentModal}
      width={800}
      height={250}
      viewModal={viewForm}
      changeViewModal={setViewForm}
    />
  );
}