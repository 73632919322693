import React from 'react'
import { ListItems } from './ListItems'

export const MainMenu = React.memo(({listItems, isView}) => {

    return (
        <>
            <ul className={isView ? 'show-menu' : ''}>
                {
                    listItems && listItems.length > 0 &&
                    listItems.map((element) => {
                        return <ListItems key={element.id} element={element}  tabIndex="0"/>
                    })
                }
            </ul>
        </>
    )
})
